




























































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { setTimeout } from 'timers';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends Vue {
  @Prop({ type: Object, required: false }) wellEventDetails?: any;

    windowWidth = window.innerWidth;

     dataLoading = true;

     get isMobile() {
       return this.windowWidth < 769;
     }

     get currentWellType(): any {
       if (tasqsListModule.activeTasq && this.$route.query.type != 'producing') {
         return 'id';
       }

       if (assetsModule.activeTasq) {
         if (scheduleModule.activeComponent) {
           return 'scheduled';
         }

         return 'producing';
       }
       return this.$route.query.type;
     }

     get tasq() {
       if (tasqsListModule.isBatchResponding) {
         if (!tasqsListModule.activeTasq) {
           tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
         }
         return tasqsListModule.activeTasq as TasqJob;
       }

       if (assetsModule.activeTasq == undefined) {
         return tasqsListModule.activeTasq as TasqJob;
       }

       if (this.currentWellType == 'producing') {
         return assetsModule.activeTasq as TasqJob;
       }

       return tasqsListModule.activeTasq as TasqJob;
     }

     getReadableWellHistoryComment(comment) {
       var readableComment = 'Confirmed';
       if (comment.ResponseData == null) {
         readableComment = '';
         if (comment.Type != null && comment.Type == 'CYGNET') {
           readableComment = 'Cygnet Comment';
         }
       } else if (comment.ResponseData.UserResponse != null) {
         if (comment.ResponseData.UserResponse.Selection == 'YES') {
           var readableComment = 'Confirmed';
         } else if (comment.ResponseData.UserResponse.Selection == 'NO') {
           var readableComment = 'No';
         } else if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
           var readableComment = 'Not sure of';
         } else if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
           var readableComment = 'Closed automatically';
         }

         if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.PredictionType && (comment.PredictionType.toLowerCase() == 'anomaly' || comment.PredictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
           readableComment += ' - Anomaly';
         } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
           readableComment += ' - Off-Target';
         } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
           readableComment += ' - Off-Target RT';
         } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'setpoint')) {
           readableComment += ' - Setpoint';
         }

         if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
           readableComment += ': ';
           for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
             readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
           }
         }
       } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
         readableComment += ' - Off-Target';
       } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
         readableComment += ' - Off-Target RT';
       } else if (comment.PredictionType && comment.PredictionType.toLowerCase() == 'setpoint') {
         readableComment += ' - Setpoint';
       } else {
         readableComment += ` - ${comment.PredictionType}`;
       }

       if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
         if (comment.Payload != null && comment.Payload.Outcome != null) {
           readableComment += ` - ${comment.Payload.Outcome}`;
         }
       }

       readableComment = readableComment.replace('Off-Target RT', 'Off-Target Real Time');

       return readableComment;
     }

     created() {
       console.log(this.wellEventDetails);
       setTimeout(() => {
         this.dataLoading = false;
       }, 1000);
     }
}

